import { useState } from "react"
import { DownloadModal } from "../components/modals/DownloadModal"
import { COLORS } from "../utils"
import './animations.css'

export const Stacks = () => {
    const [state, setState] = useState({
        modal: {
            shown: false,
            title: null
        }
    })
    return <div>
       {state.modal.shown && <DownloadModal data={state.modal} setState={setState}  />}
        <p className="fs-3 mt-3 mb-sm-4 mb-3">Очень круто, что вы сразу переходите к делу</p>
        <p className="fs-4 fw-light mb-sm-5 mb-4">В этом разделе достаточно сухая информация, однако если вы захотите вернуться назад, сверху теперь есть кнопка
             <span className="fw-bold ms-3">Хочу назад</span>. А еще если кликнуть по скиллу с зеленой меткой – можно найти пример работы.</p>
        <p className="fs-3 mb-3">Product</p>
        <StackLine title='Менеджерил' setState={setState} />
        <StackLine title='Использовал' setState={setState} />

        <p className="fs-3 mt-sm-5 mt-4 mb-3">Разработка</p>
        <StackLine title='Писал' setState={setState} />
        <StackLine title='Базы данных' setState={setState} />
        <StackLine title='Развертывал' setState={setState} />
    </div>
}

const GREEN_DATA = ['Фин. модель', 'Figma']

const DATA = {
    'Менеджерил': ['Discovery', 'Delivery', 'Release'],
    'Использовал': ['Продуктовые метрики', 'Фин. модель', 'Custdev’ы', 'Agile', 'Jira', 'ClickUp', 'Confluence', 'Notion', 'Slack', 'Figma'],
    'Писал': ['Python', 'FastAPI', 'HTML', 'CSS', 'Bootstrap'],
    'Базы данных': ['PostgreSQL', 'Redis', 'TortoiseORM'],
    'Развертывал': ['Linux', 'Git', 'Docker', 'Jenkins'],
}

const StackLine = ({ title, setState }) => {
    return <div className="fw-light mb-4">
        <p className="fs-5 mb-3">{title}</p>
        <div className="row g-2 g-sm-4">
            {DATA[title].map((el, i) => <Skill title={el} key={i} setState={setState} /> )}
        </div>
    </div>
}

const Skill = ({ title, setState }) => {
    const hasModal = GREEN_DATA.indexOf(title) >= 0
    return <div className="col-auto">
        <div 
            style={{ cursor: hasModal ? 'pointer': 'auto' }}
            className="d-flex bg-white rounded py-2 px-sm-5 px-4" 
            onClick={() => hasModal && setState(p => ({ ...p, modal: { ...p.modal, shown: true, title: title } }))}>
            <p className="m-auto fs-5">{title}</p>
            {hasModal && <div className="my-auto ms-3 rounded-circle" style={{ width: '13px', height: '13px', backgroundColor: COLORS.success }}></div>}
        </div>
    </div>
}