import { COLORS } from "../utils"
import { ReactComponent as VKSvg } from '../icons/vk.svg'
import { ReactComponent as VCRUSvg } from '../icons/vcru.svg'
import { ReactComponent as SberSvg } from '../icons/sber.svg'
import { ReactComponent as RBCSvg } from '../icons/rbc.svg'
import { ReactComponent as ExcelSvg } from '../icons/excel.svg'

export const SMALL_CASES = [{
    icon: <ExcelSvg />,
    title: 'фин. модель',
    link: 'https://docs.google.com/spreadsheets/d/16ndFZs1iUfMDJAmSBOwf8s7uIpAUiWSr/edit?gid=25511008#gid=25511008'
},{
    icon: <ExcelSvg />,
    title: 'матрица RACI',
    link: 'https://docs.google.com/spreadsheets/d/1T1pBXsoV89BgJ1NAGNwQ1akfiOYK31zy/edit?gid=1487200747#gid=1487200747'
},{
    icon: <VCRUSvg />,
    title: 'читать про стенд',
    link: 'https://vc.ru/life/1149575-kak-bez-vedoma-organizatorov-my-postavili-svoi-stend-na-rvf-2024'
},{
    icon: <RBCSvg />,
    title: 'читать про Хартл',
    link: 'https://trends.rbc.ru/trends/education/64d0cfb49a79477a524466f4'
},{
    icon: <VKSvg />,
    title: 'интервью со мной',
    link: 'https://vk.com/nticontest?w=wall-114751985_24910'
},{
    icon: <SberSvg />,
    title: 'кейс со Сбером',
    link: 'https://developers.sber.ru/portal/cases/hurtle?ysclid=lyhfy3jz2z879218075'
}]


export const CASES_DATA_PRODUCT = [{
    title: '💜  мой стартап',
    text: 'Сделал HR-плафторму, внедрил туда нейронки и вывел на реальный рынок. Сделал B2B продажи, участвовал в экспо и проделал много Product-работы',
    content: <div className="d-flex justify-centent-center mt-auto text-decoration-underline pt-3">
        <a style={{ color: COLORS.secondary, cursor: 'pointer' }} href="/#product" className="me-5">
            что это
        </a>
        <p style={{ cursor: 'pointer' }} onClick={() => window.open('https://hurtle.ru')}>
            сайт
        </p>
    </div>
}
// ,{
//     title: '🖌  UI-кит',
//     text: 'Совместно с продуктовой командой разработал UI-кит для своего стартапа. Было проработано множество элементов, посмотреть можно по ссылке',
//     content: <p style={{ cursor: 'pointer' }} onClick={() => window.open('https://www.figma.com/design/toZERjREbr1b7jsq3i4pXB/%D0%9F%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B-%D1%80%D0%B0%D0%B1%D0%BE%D1%82?node-id=0-1&t=EwjC0b5ejEp6elti-1')} className="text-center mt-auto text-decoration-underline">
//         фигма
//     </p>
// }
]
export const CASES_DATA_PRESENTATIONS = [{
    picture: 'pitch.png',
    content: <p style={{ cursor: 'pointer' }} onClick={() => window.open('https://drive.google.com/file/d/1fayXmF80S63wLpY7f8FKO70e3xuf8RwH/view?usp=sharing')} className="text-center mt-auto text-decoration-underline fw-light">
        питчдек стартапа
    </p>
},{
    picture: 'study.png',
    content: <p style={{ cursor: 'pointer' }} onClick={() => window.open('https://drive.google.com/file/d/1cRiHtg_e7ceYw22ZcxX-6CqArJEscoTO/view?usp=sharing')} className="text-center mt-auto text-decoration-underline fw-light">
        преза по учёбе
    </p>
},{
    picture: 'test_task.png',
    content: <p style={{ cursor: 'pointer' }} onClick={() => window.open('https://drive.google.com/file/d/1M_4aYSKpa-OQ4F8ntqr0oZuWkO8gZFsa/view?usp=sharing')} className="text-center mt-auto text-decoration-underline fw-light">
        тестовое задание
    </p>
}]

export const ABOUT_HURTLE = [{
    picture: 'mts.png',
    paragraphs: ['Стартап с высоким потенциалом развития', 'MTS Startup Hub']
},{
    picture: 'mgimo.png',
    paragraphs: ['Лучший стартап предакселератора МГИМО', 'Yandex Cloud']
},{
    picture: 'innopolis.png',
    paragraphs: ['Приглашение в роли эксперта', 'Иннополис']
},{
    picture: 'mgtu.png',
    paragraphs: ['Лучший стенд стартап-аллеи','МГТУ им. Баумана']
},{
    picture: 'angel.png',
    paragraphs: ['Рекомендация к инвестированию', 'Бизнес-ангел']
},]

export const HURTLE_SKILLS = ['Discovery', 'Delivery', 'Release', 'Продуктовые метрики', 'Jira', 'ClickUp', 'Confluence', 'Custdev’ы', 'Agile', 'Slack', 'Notion', 'Python', 'FastAPI', 'HTML', 'CSS', 'Bootstrap', 'Git', 'PostgreSQL', 'Redis', 'TortoiseORM', 'Docker', 'Linux', 'Jenkins', 'Unit-экономика', 'Figma', 'React', 'TypeScript', 'Node.js', 'Нагрузочное тестирование', 'Маркетинг', 'Рекрутинг']

export const CHAPTERSDATA = [{
    paragraphs: ['Всё началось тогда, когда у меня появился компьютер.', 'Родители подарили мне ноутбук и отпустили с ним в свободное плавание. Не помню сколько мне было, может, лет 10. Зато точно помню как символично почти сразу после покупки я лёг в больницу с пневмонией. От скуки я решил поближе познакомиться с компьютером и начал узнавать как он устроен – читал статьи про комплектацию ПК, что такое низкоуровневое программирование и как работает операционная система.', 'Зацепившись за последнее – я начал учиться переустанавливать Windows и открыл для себя VirtualBox – тут-то я и свернул на дорожку IT-индустрии и познакомился с первым инструментом, а именно виртуализацией.'],
    picture: null,
    skills: ['Виртуализация']
},{
    paragraphs: ['Тогда я решил продолжить двигаться в этом направлении и начал усиленно развиваться в системном администрировании. Учился собирать компы, разбираться в железе, настраивать окружение на серверах и писать настройки для сетей.', 'Получилось даже ничего так. Пошел на первую для себя профильную олимпиаду и занял призовое место на JuniorSkills. Это было круто, подарило огромный заряд и желание дальше развиваться в IT.'],
    picture: 'medal.png',
    skills: ['Linux', 'Cisco']
},{
    paragraphs: ['Немного поразмыслив я пошёл развиваться в программировании. Записался в местный центр обучения кодингу и начал учить С++. Год страданий и нахождения в группе с парнями по 18-20 лет – и вот я получаю “диплом” программиста. Радости нет предела и я бегу практиковаться.', 'Начинаю думать какие проекты я мог бы реализовать на плюсах... И понял, что никакие. Принимаю решение учить Python и развиваться в нем самостоятельно.'],
    picture: 'programmer.png',
    skills: ['C++', 'ООП']
},{
    paragraphs: ['Почитав статьи о том, как найти работу, я начал активно участвовать в хакатонах. По результатам одного из них я попал на стажировку в Haclever. Там я писал микросервисы на FastAPI, развертывал их на сервере и взаимодействовал с разными командами разработки.', 'Кстати про хакатоны. На нескольких из них я занимал призовые места, но самый значимый из них был хакатон VK в Кемерово. Во время карантина мы с другом написали сервис для дистанционного обучения в телеграме и победили с ним.'],
    picture: 'vk_text.png',
    skills: ['Python', 'FastAPI', 'Docker']
},{
    paragraphs: ['Достигнув своего максимума на предыдущем месте работы, я пошёл дальше практиковаться и учиться на хакатонах, где нашёл своё следующее место работы – Chatex.', 'Здесь я занимался разработкой личного кабинета для крупнейшего в то время криптобанка, проводил UX-исследования и писал очень много кода.'],
    picture: 'hackaton.png',
    skills: ['Vue.js', 'SCSS', 'Notion']
},{
    paragraphs: ['К сожалению, Chatex закрылся из-за наложенных санкций и я вновь был вынужден идти на рынок труда. Новым местом работы для меня стал Bottec.', 'Здесь я на более продвинутом уровне писал микросервисы на Python – использовал системы очередей, более сложную контейнеризацию и новые фреймворки. Было интересно, проработал больше года здесь и решил, что хочу не кодить, а управлять процессом разработки.'],
    picture: null,
    skills: ['Jenkins', 'PostgreSQL', 'Redis', 'RabbitMQ', 'Django']
},]

export const HURTLE_CHAPTERS = [{
    title: 'Команда разработки',
    paragraphs: ['Под моим руководством была выстроена работа нескольких команд разработки с использованием Scrum-методологии. В стек нашего продукта вошли React, FastAPI, Node.js, Redis, BullMQ. В рамках развития Хартл была также создана отдельная команда стажеров, для которых я создал внутреннюю систему обучения и интеграции в наш проект.'],
    picture: 'offer.png',
    linksContent: <p className='mt-sm-5 mt-4 fs-5' 
        onClick={() => window.open('https://t.me/vikentiykopytkov')} 
        style={{ color: COLORS.secondary, textDecoration: 'underline', cursor: 'pointer' }}>если хотите гитхаб – пишите</p>
},{
    title: 'Продуктовая команда',
    paragraphs: ['Внутри стартапа я создал свою продуктовую команду из бизнес-аналитика, Product-менеджера, дизайнера и SMM-специалиста.', 'Мною также была проработана маркетинговая стратегия, которая включала всеобъемлющее продвижение с минимальным бюджетом. Трехмесячный охват наших социальных сетей составил 6 млн человек, а на наших стендах на очных мероприятиях побывало более 500 человек.'],
    picture: 'stories.png',
    linksContent: <div className='d-flex flex-column fs-5 fw-light gap-sm-4 gap-2 mt-sm-5 mt-4 '>
        <p style={{ color: COLORS.primary, textDecoration: 'underline', cursor: 'pointer' }} onClick={() => window.open('https://t.me/hurtleru')}>Ссылка на телеграм</p>
        <p style={{ color: COLORS.secondary, textDecoration: 'underline', cursor: 'pointer' }} onClick={() => window.open('https://instagram.com/hurtle.ru')}>Ссылка на нельзяграм</p>
    </div>
},{
    title: 'Инновации в рекрутменте',
    paragraphs: ['Внутри Хартл я в течение двух лет генерировал гипотезы, постоянно их тестировал и создавал очень разные, но нужные HR-у продукты. Среди них были как небольшие (например, сервис карьерных консультаций), так и нестандартные – мы сделали инструмент для анализа кандидатов с приминением генеративного ИИ.', 'Нейрорезюме – это цифровой двойник кандидата, которого мы обучали на данных из видеособеседований и резюме. С помощью него рекрутер мог гораздо быстрее узнать нужную информацию про кандидата и эффективнее закрывать вакансии.'],
    picture: 'iphone.png',
    linksContent: <></>
},{
    title: 'Аутсорс команды',
    paragraphs: ['Внутри стартапа создал выделенную команду для внешней разработки. Реализовали цифровые проекты для МГИМО, крупного визового агентства и других заказчиков.', 'Сделали цифровую платформу для проведения ассестмента студентов, разработали ПО для интеграции с внешним сайтом по выдаче виз в страны Шенгенского соглашения.'],
    picture: 'document.png',
    linksContent: <></>
},{
    title: 'Лекции',
    paragraphs: ['За время существования стартапа я дал около 10 лекций на разных мероприятиях. Это были площадки Сколково, Иннополиса, МГИМО и разных фестивалей. В своей работе я считаю крайне важным делиться опытом и самому учиться на чужих ошибках – поэтому стараюсь как можно чаще бывать на различных IT-конференциях и мероприятиях.'],
    picture: 'lections.png',
    linksContent: <></>
},{
    title: 'Моё нейрорезюме',
    paragraphs: ['Вот и всё. Конечно же, я всё равно много чего не рассказал – где я учусь, чем увлекаюсь и почему ушёл из стартапа. Всё это можно узнать у моего цифрового двойника – нейрорезюме. Кстати это та самая технология, которую я создал внутри Хартл. Как-то так, надеюсь, стало яснее, кто я такой :)'],
    picture: null,
    linksContent: <></>
},]