import { Modal } from "react-bootstrap";
import { COLORS } from "../../utils";

import { ReactComponent as FigmaSvg } from '../../icons/figma.svg'
import { ReactComponent as ExcelSvg } from '../../icons/excel.svg'

export const DownloadModal = ({ data, setState }) => {
    return (
      <Modal
        dialogClassName='rounded-4'
        style={{ background: 'transparent' }}
        centered
        size='md'
        show={data.shown}
        onHide={() => setState((p) => ({ ...p, modal: {
            shown: false,
            title: null
        } }))}
      >
        <Modal.Header closeButton>
          <Modal.Title >{data.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="d-flex rounded-3 mx-sm-4 py-3 my-4" style={{ border: '1px solid ' + COLORS.dark_gray }}>
                {CONTENT[data.title]}
            </div>
        </Modal.Body>
      </Modal>
    );
};

const downloadFile = () => {
    var url = window.URL.createObjectURL('');

    // Создаем ссылку для скачивания файла
    var a = document.createElement('a');
    a.href = url;
    a.download = '.pdf'; // Имя для сохранения файла
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
}

const CONTENT = {
    'Фин. модель': <div className="d-flex align-items-center m-auto" style={{ cursor: 'pointer' }} onClick={downloadFile}>
        <ExcelSvg className="me-4" />
        <p className="text-decoration-underline fs-6">скачать пример</p>
    </div>,
    'Figma': <div className="d-flex align-items-center m-auto" style={{ cursor: 'pointer' }} onClick={() => window.open('https://www.figma.com/design/toZERjREbr1b7jsq3i4pXB/%D0%9F%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B-%D1%80%D0%B0%D0%B1%D0%BE%D1%82?node-id=0-1&t=EwjC0b5ejEp6elti-1')}>
        <FigmaSvg className="me-4" />
        <p className="text-decoration-underline fs-6 ">документ с примерами</p>
    </div>,
}