export const MAX_WIDTH = '1300px'
export const TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjJhODc1NWUxLWU1MDktNDllOS1hMGRmLTNkNDE1MWM0NTY5MyIsInJvbGUiOiJBUFBMSUNBTlQiLCJpYXQiOjE3MjA3OTc3MjU1MTJ9.gUHIFhHpiSXW6QAOgb6n2yMC37_1sK-D1jgdoE7Z3PU'
export const COLORS = {
    primary: '#01A0FA',
    secondary: '#8F4EE3',
    primary_light: '#D9EFFF',
    danger: '#FF4E4E',
    gray: '#F2F2F2',
    dark_gray: '#9F9F9F',
    warning_light: '#F7B568',
    warning: '#F28304',
    success: '#1ED500',
    bg: '#F9F9FB',

}

export const SOURCE = {
	url:
		process.env.REACT_APP_NODE_ENV === 'dev'
			? 'https://dev.hurtle.ru/api/v1/'
			: 'https://hurtle.ru/api/v1/',
	source_url: 'https://resource.hurtle.ru/',
}

export const toTime = datetime => {
	return new Date(datetime).toLocaleTimeString().slice(0, 5)
}
