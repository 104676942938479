import * as yup from "yup"
import { SOURCE } from "./utils"

export const CreateAiMessageSchema = yup.object({
    question: yup
        .string()
        .required()
        .min(4, 'сообщение должно быть не менее 4 символов')
        .max(4000, 'максимальная длина сообщения - 4000 символов'),
})

export const getMyAccount = async (access_token, params) => {
    try {
        const query = new URLSearchParams()
        for (const key in params) {
            if (params[key] !== '' && params[key] !== null && params[key] !== undefined) {
                if (Array.isArray(params[key])){
                    params[key].forEach(el => {
                        query.append(key, el)
                    });
                }
                else query.append(key, params[key]);
            }
          }
      return await fetch(SOURCE.url + 'applicants/me?' + query.toString(), {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: access_token,
        },
      })
    } catch (error) {
      console.error(error)
      return null
    }
  }

export async function createAiChat(token, applicantId) {
    try {
        const response = await fetch(
            `${SOURCE.url}applicantAiChats`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: token,
                },
                body: JSON.stringify({
                    applicantId: applicantId
                })
            }
        )
        if (!response.ok) return false
        return await response.json()
    } catch (error) {
        console.error(error)
        return false
    }
}

export async function createAiMessage(token, chatId, question, dispatch) {
    try {
        try {
            await CreateAiMessageSchema.validate({ question: question })
        } catch (err) {
           console.log(err.errors)
            return false
        }

        const response = await fetch(
            `${SOURCE.url}applicantAiChatMessages`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: token,
                },
                body: JSON.stringify({
                    chatId: chatId,
                    question: question
                })
            }
        )
        if (!response.ok) {
            await console.log('Ошибка')
            return false
        }
        return await response.json()
    } catch (error) {
        console.error(error)
        return false
    }
}