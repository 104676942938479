// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes slideFadeInLeft {
    0% {
        opacity: 0;
        transform: translateX(-50%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideFadeInRight {
    0% {
        opacity: 0;
        transform: translateX(50%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideFadeInTop {
    0% {
        opacity: 0;
        transform: translateY(50%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.slideTop {
    animation: slideFadeInTop 1s ease-in-out forwards;
}

.slideRight {
    animation: slideFadeInRight 1s ease-in-out forwards;
}


.slideLeft {
    animation: slideFadeInLeft 1s ease-in-out forwards;
}
  
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fade {
    animation: fadeIn 1s ease-in-out forwards;
}`, "",{"version":3,"sources":["webpack://./src/screens/animations.css"],"names":[],"mappings":"AAAA;IACI;QACI,UAAU;QACV,2BAA2B;IAC/B;IACA;QACI,UAAU;QACV,wBAAwB;IAC5B;AACJ;;AAEA;IACI;QACI,UAAU;QACV,0BAA0B;IAC9B;IACA;QACI,UAAU;QACV,wBAAwB;IAC5B;AACJ;;AAEA;IACI;QACI,UAAU;QACV,0BAA0B;IAC9B;IACA;QACI,UAAU;QACV,wBAAwB;IAC5B;AACJ;;AAEA;IACI,iDAAiD;AACrD;;AAEA;IACI,mDAAmD;AACvD;;;AAGA;IACI,kDAAkD;AACtD;;AAEA;IACI;QACI,UAAU;IACd;IACA;QACI,UAAU;IACd;AACJ;;AAEA;IACI,yCAAyC;AAC7C","sourcesContent":["@keyframes slideFadeInLeft {\n    0% {\n        opacity: 0;\n        transform: translateX(-50%);\n    }\n    100% {\n        opacity: 1;\n        transform: translateX(0);\n    }\n}\n\n@keyframes slideFadeInRight {\n    0% {\n        opacity: 0;\n        transform: translateX(50%);\n    }\n    100% {\n        opacity: 1;\n        transform: translateX(0);\n    }\n}\n\n@keyframes slideFadeInTop {\n    0% {\n        opacity: 0;\n        transform: translateY(50%);\n    }\n    100% {\n        opacity: 1;\n        transform: translateY(0);\n    }\n}\n\n.slideTop {\n    animation: slideFadeInTop 1s ease-in-out forwards;\n}\n\n.slideRight {\n    animation: slideFadeInRight 1s ease-in-out forwards;\n}\n\n\n.slideLeft {\n    animation: slideFadeInLeft 1s ease-in-out forwards;\n}\n  \n@keyframes fadeIn {\n    0% {\n        opacity: 0;\n    }\n    100% {\n        opacity: 1;\n    }\n}\n\n.fade {\n    animation: fadeIn 1s ease-in-out forwards;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
