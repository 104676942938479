import { CASES_DATA_PRESENTATIONS, CASES_DATA_PRODUCT, SMALL_CASES } from './utils'

import './animations.css'

export const Cases = () => {
    return <div>
        <p className="fs-3 mt-3 mb-sm-4 mb-3">Очень круто, что вы сразу переходите к делу</p>
        <p className="fs-4 fw-light mb-sm-5 mb-4">В этом разделе достаточно сухая информация, однако если вы захотите вернуться назад, сверху теперь есть кнопка
             <span className="fw-bold ms-3">Хочу назад</span>
        </p>
        <p className='fs-2'>Продакт</p>
        <div className='row row-cols-lg-3 row-cols-md-2 row-cols-1 gx-sm-5 gy-md-4 gy-3 mt-sm-3 mt-2 fade'>
            {CASES_DATA_PRODUCT.map((el, i) => <Case data={el} key={i} />)}
        </div>
        <p className='fs-2 mt-sm-5 mt-4'>Презентации</p>
        <div className='row row-cols-lg-3 row-cols-md-2 row-cols-1 gx-5 gy-md-4 gy-3 mt-sm-3 mt-2 fade'>
            {CASES_DATA_PRESENTATIONS.map((el, i) => <Case data={el} key={i} />)}
        </div>
        <p className='fs-2 mt-sm-5 mt-4'>Экономика</p>
        <div className='row row-cols-lg-3 row-cols-md-2 row-cols-1 gx-5 gy-md-4 gy-2 mt-sm-3 mt-2'>
            {SMALL_CASES.slice(0,2).map((el, i) => <SmallCase data={el} key={i} />)}
        </div>
        <p className='fs-2 mt-sm-5 mt-4'>Публикации в СМИ</p>
        <div className='row row-cols-lg-3 row-cols-md-2 row-cols-1 gx-5 gy-md-4 gy-2 mt-sm-3 mt-2'>
            {SMALL_CASES.slice(2).map((el, i) => <SmallCase data={el} key={i} />)}
        </div>
    </div>
}

export const Case = ({ data }) => {
    return <div className='col'>
        <div className="d-flex flex-column bg-white text-center align-items-center rounded-4 p-md-4 p-3 h-100 ">
            {data.picture ? <img className='mb-3' 
                src={require('../images/' + data.picture)} style={{ objectFit: 'contain', width: '100%', height: 'auto', maxHeight: '200px' }} /> :
            <>
                <p className='fs-4 mb-sm-4 mb-3 mt-sm-3'>{data.title}</p>
                <p className='fs-5 fw-light lh-lg' style={{ maxWidth: '80%' }}>
                    {data.text}
                </p>
            </>}
            {data.content}
        </div>
    </div>
}

export const SmallCase = ({ data }) => {
    return <div className='col'>
        <div className='d-flex align-items-center justify-content-center bg-white p-3 rounded-4'>
            {data.icon}
            <p style={{ cursor: 'pointer' }} onClick={() => window.open(data.link)} className="text-center text-decoration-underline fw-light ms-3">
                {data.title}
            </p>
        </div>
    </div> 
}