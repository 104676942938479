
import { Stack } from 'react-bootstrap';
import './buttons.scss';
import { Route, Routes } from 'react-router-dom';
import { Main } from './screens/Main';
import { MAX_WIDTH, COLORS } from './utils';
import { Header } from './components/header/Header';
import { Cases } from './screens/Cases';
import { Stacks } from './screens/Stack';

function App() {
  return <Stack style={{minHeight: '100vh', backgroundColor: COLORS.bg}}>
    <div className="mx-auto w-100 pb-5 px-3" style={{ maxWidth: MAX_WIDTH }}>
      <Header />
      <Routes>
        <Route path='/' element={<Main />} />
        <Route path='/stack' element={<Stacks />} />
        <Route path='/cases' element={<Cases />} />
      </Routes>
    </div>
  </Stack>
}
export default App;
