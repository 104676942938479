import { useEffect, useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router';
import { COLORS, PASTEL_COLORS } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';


// let i = Math.floor(Math.random() * PASTEL_COLORS.length);

export const Header = () => {
  const navigate = useNavigate();
  const loc = useLocation();
  const user = useSelector((state) => state.userSlice.user);

  useEffect(() => {
    const navbarCollapse = document.getElementById('responsive-navbar-nav');
    if (navbarCollapse && navbarCollapse.classList.contains('show')) {
      navbarCollapse.classList.remove('show');
    }
  }, [loc.pathname]);


  return (
    <div className='py-2' style={{ transition: 'none', outline: 'none' }}>
      <Navbar collapseOnSelect expand='md'>
        <Navbar.Brand className='me-4'>
          <Nav.Link eventKey='1'>
              <p onClick={() => navigate('/')} className='fw-normal' style={{ marginTop: '-3px' }}>
               {loc.pathname === '/' ? 'Здесь вы найдете' : 'Хочу назад'}
              </p>
          </Nav.Link>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls='responsive-navbar-nav'
          bsPrefix='no_outline'
          className='d-block d-md-none'
        >
          <img
            src={'https://resource.hurtle.ru/menu.svg'}
            style={{ height: '30px',  }}
            className='bg-white'
            alt=''
          />
        </Navbar.Toggle>
        <Navbar.Collapse id='responsive-navbar-nav'>
          <MainHeader />
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

const MainHeader = () => {
  const loc = useLocation()
  const navigate = useNavigate()
    return <div className='d-flex fs-5 fw-light gap-4'>
        <Nav.Link
          onClick={() => navigate('/stack')}
          style={{ borderBottom: (loc.pathname === '/stack' ? '2.5px' : '1px' ) + ' solid black' }} className='p-2'>
            Мой стек
        </Nav.Link>
        <Nav.Link
          onClick={() => navigate('/cases')}
          style={{ borderBottom: (loc.pathname === '/cases' ? '2.5px' : '1px' ) + ' solid black' }} className='p-2'>
            Кейсы
        </Nav.Link>
        <a
          href={'/#twin'}
          style={{ borderBottom: '1px solid black', color: COLORS.secondary, textDecoration: 'none', cursor: 'pointer'}} className='p-2'>
            Моего двойника
        </a>
    </div>
}







