// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn.primary {
  background-color: var(--primary) !important;
  color: white !important;
}
.btn.primary:hover {
  background-color: var(--primary-hover) !important;
  color: white !important;
}
.btn.secondary_outline {
  border: 1px solid var(--secondary) !important;
  color: var(--secondary) !important;
}
.btn.secondary_outline:hover {
  background-color: var(--secondary) !important;
  color: white !important;
}
.btn.danger {
  background-color: var(--danger) !important;
  color: white !important;
}
.btn.danger:hover {
  background-color: var(--danger-hover) !important;
  color: white !important;
}
.btn.warning {
  background-color: var(--warning) !important;
  color: white !important;
}
.btn.warning:hover {
  background-color: var(--warning-hover) !important;
  color: white !important;
}`, "",{"version":3,"sources":["webpack://./src/buttons.scss"],"names":[],"mappings":"AACI;EACI,2CAAA;EACA,uBAAA;AAAR;AAGI;EACI,iDAAA;EACA,uBAAA;AADR;AAII;EACI,6CAAA;EACA,kCAAA;AAFR;AAKI;EACI,6CAAA;EACA,uBAAA;AAHR;AAMI;EACI,0CAAA;EACA,uBAAA;AAJR;AAMI;EACI,gDAAA;EACA,uBAAA;AAJR;AAOI;EACI,2CAAA;EACA,uBAAA;AALR;AAQI;EACI,iDAAA;EACA,uBAAA;AANR","sourcesContent":[".btn {\n    &.primary {\n        background-color: var(--primary) !important;\n        color: white !important;\n    }\n\n    &.primary:hover {\n        background-color: var(--primary-hover) !important;\n        color: white !important;\n    }\n\n    &.secondary_outline {\n        border: 1px solid var(--secondary) !important;\n        color: var(--secondary) !important;\n    }\n\n    &.secondary_outline:hover {\n        background-color: var(--secondary) !important;\n        color: white !important;\n    }\n\n    &.danger {\n        background-color: var(--danger) !important;\n        color: white !important;\n    }\n    &.danger:hover {\n        background-color: var(--danger-hover) !important;\n        color: white !important;\n    }\n\n    &.warning {\n        background-color: var(--warning) !important;\n        color: white !important;\n    }\n\n    &.warning:hover {\n        background-color: var(--warning-hover) !important;\n        color: white !important;\n    }\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
