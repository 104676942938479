import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as ArrowDownSvg } from '../icons/arrow_down.svg'
import { ReactComponent as MailSvg } from '../icons/mail.svg'
import { ReactComponent as TelegramSvg } from '../icons/telegram.svg'
import { ReactComponent as WhatsappSvg } from '../icons/whatsapp.svg'
import { ABOUT_HURTLE, CHAPTERSDATA, HURTLE_CHAPTERS, HURTLE_SKILLS } from './utils'
import { setUser } from '../store/slices/userSlice'
import { getMyAccount } from '../api'
import { useEffect } from 'react'
import { NeuroResume } from './neuro/NeuroResume'
import { COLORS } from '../utils'

import './animations.css'

export const Main = () => {
    const dispatch = useDispatch()
    const user = useSelector(state => state.userSlice.user)
    useEffect(() => {
        async function fetchData() {
			try {
				const response = await getMyAccount(user.access_token, { include: ['aiChats'] })
				if (!response.ok) {
					return
				}
				const data = await response.json()

				dispatch(
					setUser({
						...data,
                        access_token: user.access_token,
						loaded: true,
					})
				)
			} catch (e) {
			}
		}

		if (user.access_token !== undefined) {
			fetchData()
		} else {
			dispatch(
				setUser({
					...user,
                    access_token: user.access_token,
					loaded: true,
				})
			)
		}
    }, [])


    return  <div className="d-flex flex-column pt-sm-0 pt-3">
        <div className="d-flex flex-column" style={{ minHeight: '86vh' }}>
            <div className='row my-sm-auto gy-4'>
                <div className='col-md-8'>
                    <div className='row mb-sm-4 mb-3'>
                       {['Активно ищу работу', 'Middle+'].map((el,i) => <Skill key={i} title={el} />)}
                    </div>
                    <div className='d-flex flex-sm-column flex-row gap-3'>
                        <p className='slideLeft' style={{ fontSize: 'min(5rem, 5vw)', lineHeight: 'min(5.1rem, 5.1vw)' }}>
                            Викентий
                        </p>
                        <p className='slideRight' style={{ fontSize: 'min(5rem, 5vw)', lineHeight: 'min(5.1rem, 5.1vw)' }}>
                            Копытков
                        </p>
                    </div>
                    <div className='slideTop'>
                        <p className='fs-2 mb-sm-2 mt-3' style={{ color: '#959597' }}>
                            тот, кто вам нужен
                        </p>
                        <p className='fs-4' style={{ color: '#C7C7C9' }}>
                            если вы не зашли просто <br/>
                            посмотреть кто я такой
                        </p>
                    </div>
                   
                   
                </div>
                <div className='col'>
                    <div className='d-flex flex-column bg-white rounded-4 p-md-5 p-4 ms-auto text-center'>
                        <p className='fs-3'>Быстро узнать, что нужно</p>
                        <p className='fs-5 fw-light' style={{ color: '#959597' }}>Я советую прочитать все разделы</p>
                        <a href='#product' className='btn primary rounded w-100 py-sm-3 py-2 mt-sm-5 mt-4 fw-light'>
                            Про работу продактом
                        </a>
                        <a href='#programmer' className='btn primary rounded w-100 py-sm-3 py-2 mt-3 fw-light'>
                            Про работу разрабом
                        </a>
                    </div>
                    <Links />
                </div>
            </div>
            <ArrowDownSvg className='mx-auto mt-auto' />
        </div>

        <div className='mt-sm-5 pt-sm-5 mt-4'>
            <p className='fs-1 mb-sm-5 mb-4'>Введение</p>
            <div className='row gy-3'>
                <div className='col-md-4'>
                    <div className='d-flex flex-column align-items-center fw-light text-center'>
                        <img className='m-auto' src={require('../images/portrait.png')} style={{ objectFit: 'contain', width: '100%', height: 'auto', maxHeight: '200px' }} />
                        <p className='fs-4 mt-4'>
                            Викентий Копытков
                        </p>
                        <p className='fs-5' style={{ color: '#364B7D' }}>
                            на фотке я,<br />
                            тот кто ищет работу
                        </p>
                    </div>
                </div>
                <div className='col'>
                    <div className='d-flex flex-column bg-white h-100 fs-4 p-sm-4 p-3 rounded-4 fw-light'>
                        <p>Привет! Рад увидеть вас на моем сайте-визитке.</p>

                        <p className='mt-3'>
                            <span className='fw-bold'>Зачем он нужен? </span>
                            Если вкратце, я ищу работу. А еще я абсолютно убежден в том, что моё резюме никак не сможет передать кто я такой как специалист. Вот я и решил сделать этот сайт, где расскажу свою жизнь, свой опыт работы и к чему я сейчас пришёл. Приятного просмотра! :)
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div className='mt-sm-5 pt-sm-5 mt-4 fw-light fs-4'>
            <p className='fs-1 '>Как я пришёл в IT</p>
            <ChapterPhoto data={CHAPTERSDATA[0]} />
            <ChapterPhoto data={CHAPTERSDATA[1]} />
            <ChapterPhoto data={CHAPTERSDATA[2]} isLeft={true} />
            <div className='mt-md-5 w-100' style={{ marginBottom: '-40px' }} id='programmer'>
                <img src={require('../images/label14.png')} className='mt-5 me-auto' style={{ objectFit: 'contain', width: 'auto', height: 'auto', maxWidth: 'min(100%, 600px)' }} />
            </div>
            <ChapterPhoto data={CHAPTERSDATA[3]}  />
            <ChapterPhoto data={CHAPTERSDATA[4]} isLeft={true} />
            <ChapterPhoto data={CHAPTERSDATA[5]} />
        </div>

        <p className='fs-1 my-sm-5 my-4' id='product'>Итак, Хартл</p>
        <div className='row gx-sm-3 gy-sm-3 g-2'>
            {HURTLE_SKILLS.map((el, i) => <Skill title={el} key={i} />)}
        </div>

        <p className='fw-light fs-4 mt-sm-5 mt-4'>Искал Иванов, гражданин обычный, работу себе перспективную.<br />
            Искал работу повсюду, лично, позицию заняв активную. <br />
            С досады готовый нырнуть башкой в любой вулканический кратер,<br /> 
            Он вдруг увидел плакат простой с названьем загадочным Хартл. </p>
        <div className='w-100 my-sm-5 my-4'>
            <img src={require('../images/baumanka.png')} className='me-auto' style={{ objectFit: 'contain', width: '100%', height: 'auto', }} />
        </div>

        <p className='fw-light fs-4'>Хартл – это мой стартап, где я был CEO и выполнял функции продакта. Эта история началась еще в 2021 году, когда я решил создать сервис для поиска работы начинающим разработчиком. Где-где, а вот с этим продуктом я пережил огромное количество челленджей, трудностей и продуктовых задач. Поэтому здесь будут только ключевые факты из истории стартапа.</p>
        <HurtleChapter data={HURTLE_CHAPTERS[0]} />
        <HurtleChapter data={HURTLE_CHAPTERS[1]} isLeft={true} />
        <HurtleChapter data={HURTLE_CHAPTERS[2]} />

        <p className='fs-2 mt-5 mb-4'>Признание стартапа</p>
        <p className='fs-4 mb-sm-4 mb-3 fw-light'>Зачем говорить, если можно показать?</p>

        <div className='row gy-sm-4 gy-3 mb-3 mb-sm-4'>
            <AboutHurtleCard data={ABOUT_HURTLE[0]} className='col-sm' />
            <AboutHurtleCard data={ABOUT_HURTLE[1]} className='col-sm' />
            
        </div>
        <div className='row gy-sm-4 gy-3 mb-sm-5 mb-4'>
            <AboutHurtleCard data={ABOUT_HURTLE[2]} />
            <AboutHurtleCard data={ABOUT_HURTLE[3]} />
            <AboutHurtleCard data={ABOUT_HURTLE[4]} />
        </div>
        <HurtleChapter data={HURTLE_CHAPTERS[3]} />
        <HurtleChapter data={HURTLE_CHAPTERS[4]} />
        <HurtleChapter data={HURTLE_CHAPTERS[5]} />
       {user.login && <NeuroResume />}

    </div>
}


export const Links = ({ isBig = true }) => <div className={'d-flex flex-row justify-content-center aligm-items-center ' + (isBig ? 'mt-4' : 'my-auto')}>
    <TelegramSvg onClick={() => window.open('https://t.me/vikentiykopytkov')} style={{ height: '25px', cursor: 'pointer' }} />
    <MailSvg onClick={() => window.open('mailto:kopytkov0000@gmail.com')} className={'my-auto ' + (isBig ? 'mx-5' : 'mx-4')} style={{ height: '24px', cursor: 'pointer' }} />
    <WhatsappSvg onClick={() => window.open('tel:+79001111111')} style={{ height: '25px', cursor: 'pointer' }} />
</div>

const AboutHurtleCard = ({ className = 'col-md', data }) => {
    return <div className={className}>
        <div className='d-flex flex-column'>
            <img src={require('../images/' + data.picture)} className='mb-3' style={{ objectFit: 'contain', maxWidth: '100%', widht: 'auto', height: 'auto', maxHeight: '500px' }} />
            {data.paragraphs.map((el, i) => <p key={i} style={{ color: '#959597' }} className={'text-center fw-light ' + (i === 0 ? '' : 'mt-1')}>{el}</p>)}
        </div>
    </div>
}




const HurtleChapter = ({ data, isLeft = false }) => {
    return <div className='fw-light fs-4 mt-sm-5 mt-4' id={data.title === 'Моё нейрорезюме' ? 'twin' : null}>
    <p className='fs-2 mb-sm-4 mb-3 fw-normal'>{data.title}</p>
    <div className='row gy-md-0 gy-4'>
        <div className={data.picture !== null ? (isLeft ? 'col-md-7 order-md-2 order-1' : 'col-md-7 order-1' ) : 'col'}>
            {data.paragraphs.map((el, i) => <p key={i} className={i === 0 ? '' : 'mt-3'}>{el}</p>)}
            {data.linksContent}
        </div>
        {data.picture !== null && <div className={'col ' + (isLeft ? 'order-1' : 'order-2')}>
            <div className={'d-flex justify-content-center ' + (isLeft ? 'justify-content-md-start' :'justify-content-md-end')}>
                <img src={require('../images/' + data.picture)} style={{ objectFit: 'contain', maxWidth: '100%', widht: 'auto', height: 'auto', maxHeight: '500px' }} />

            </div>
        </div>}
    </div>
</div>
}

const ChapterPhoto = ({ data, isLeft = false }) => {

    return <>
        <div className='row g-3 mt-sm-5 mt-4 mb-sm-4 mb-3'>
            {data.skills.map((el, i) => <Skill key={i} title={el} />)}
        </div>
        <div className='row gy-md-0 gy-4'>
            <div className={data.picture !== null ? (isLeft ? 'col-md-7 order-md-2 order-1' : 'col-md-7 order-1' ) : 'col'}>
                {data.paragraphs.map((el, i) => <p key={i} className={i === 0 ? '' : 'mt-3'}>{el}</p>)}
            </div>
            {data.picture !== null && <div className={'col ' + (isLeft ? 'order-1' : 'order-2')}>
                <div className={'d-flex justify-content-center ' + (isLeft ? 'justify-content-md-start' :'justify-content-md-end')}>
                    <img className='' src={require('../images/' + data.picture)} style={{ objectFit: 'contain', maxWidth: '100%', width: 'auto', height: 'auto', maxHeight: '500px' }} />

                </div>
            </div>}
        </div>
    </>
}

const GREEN_DATA = ['Активно ищу работу']

const Skill = ({ title }) => {
    const hasGreen = GREEN_DATA.indexOf(title) >= 0
    return <div className="col-auto">
        <div 
            className={"d-flex bg-white rounded py-2 px-3 " + (hasGreen ? 'px-sm-4' : 'px-sm-5')} >
            {hasGreen && <div className="my-auto me-3 rounded-circle" style={{ width: '13px', height: '13px', backgroundColor: COLORS.success }}></div>}
            <p className="m-auto fs-5 fw-light">{title}</p>
        </div>
    </div>
}