import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import TextareaAutosize from '../../ui/TextareaAutosize'
import { COLORS, SOURCE, toTime } from '../../utils'
import { ReactComponent as SendSvg } from '../../icons/send.svg'
import { ReactComponent as StarsSvg } from '../../icons/stars.svg'
import { ReactComponent as TailLeftSvg } from '../../icons/tail_left.svg'
import { ReactComponent as TailRightSvg } from '../../icons/tail_right.svg'
import classes from './NeuroResume.module.css'
import {createAiMessage} from "../../api"
import { Links } from '../Main'

const FAQ = [
	'Какие у тебя навыки?',
	'Где ты учился?',
	'Какой у тебя опыт работы?',
]

export const NeuroResume = () => {
	const user = useSelector(state => state.userSlice.user)
	const chatId = user?.aiChats[0]?.id
	const divRef = useRef(null)
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const [state, setState] = useState({
		chatId: chatId,
		history: [],
		loaded: true,
		question: '',
		waitingForResponse: false,
		short: false,
		canCreateMessage: false
	})

	useEffect(() => {
		if (divRef.current) {
			divRef.current.scrollTop = divRef.current.scrollHeight
		}
	}, [state.history])

	const sendQuestion = async question => {
		if (!question) return
		// if (!state.canCreateMessage) {
		// 	return
		// }

		setState({
			...state,
			question: '',
			history: [
				...state.history,
				{ id: '', createdAt: new Date(), prompt: question, response: '...' },
			],
		})
		const result = await createAiMessage(
			user.access_token,
			state.chatId,
			question,
			dispatch
		)
		if (!result) return

		setState({ ...state, history: [...state.history, result], question: '' })
	}


	if (!chatId) return <></>

	return <div className='d-flex flex-column align-items-center mt-sm-5 mt-4'>
	<div
		className='d-flex flex-column bg-white rounded-4 p-sm-5 p-4 w-100'
		style={{ minHeight: '60vh' }}
	>
		<div className='d-flex flex-row '>
			<img src={require('../../images/portrait.png')} className='me-4' style={{ objectFit: 'contain', widht: '70px', height: '70px' }} />
			<div className='d-flex flex-column justify-content-center me-auto'>
				<p className='fs-5'>Викентий Копытков</p>
				<p className='fs-6 fw-light' style={{ color: COLORS.dark_gray }}>Моя виртуальная версия</p>
			</div>
			<div className='d-sm-block d-none'>
				<Links isBig={false} />

			</div>
		</div>
		<div
			className='d-flex flex-column px-md-5 mx-auto mb-md-5 w-100 mt-sm-5 mt-3'
		>
			
			
			<div
				ref={divRef}
				className='w-100 d-flex flex-column'
				style={{
					maxHeight: '600px',
					overflowY: 'auto',
					paddingRight: '5px',
				}}
			>
				{ state.history.length > 0 &&
					state.history.map((aim, i) => <AiPair key={i} pair={aim} />)
				}
			</div>
		</div>
		<div className='mt-auto'>
			{state.loaded && state.history.length === 0 && (
				<div className='row g-2'>
					{FAQ.slice(0, window.innerWidth > 576 ? 3 : 2).map((v, i) => (
						<PopularQuestion
							key={i}
							value={v}
							onClick={() => sendQuestion(v)}
						/>
					))}
				</div>
			)}
			<form
				onSubmit={e => e.preventDefault()}
				className='d-flex align-items-center mt-2'
			>
				<TextareaAutosize
					value={state.question}
					onChange={e => setState({ ...state, question: e.target.value })}
					maxHeight='150px'
					className='me-sm-4 me-2 rounded p-2'
					style={{ flex: '1', border: '0.7px solid ' + COLORS.dark_gray, resize: 'none'  }}
					minHeight='70px'
					defaultHeight='70px'
				/>
				<button
					disabled={!state.chatId || state.waitingForResponse || state.question === ''}
					className='btn rounded-circle d-flex'
					onClick={() => sendQuestion(state.question || '')}
					style={{
						border: 'none',
						width: '53px',
						height: '53px',
						flexShrink: '0',
						backgroundColor: COLORS.secondary,
					}}
				>
					<SendSvg className='m-auto' />
				</button>
			</form>
		</div>
	</div>
</div>
}

const PopularQuestion = ({ value, onClick }) => {
	return (
		<div className='col-auto'>
			<button
				onClick={onClick}
				className='btn secondary_outline rounded-3 p-2'
				style={{
					border: '1px solid ' + COLORS.secondary,
					color: COLORS.secondary,
					cursor: 'pointer',
				}}
			>
				{value}
			</button>
		</div>
	)
}

const AiPair = ({ pair }) => {
	return (
		<div className='d-flex flex-column'>
			<div className='d-flex mb-1 '>
				<div
					className='ms-auto d-flex flex-column ms-auto'
					style={{ maxWidth: '70%', position: 'relative', overflowX: 'hidden' }}
				>
					<p
						className='px-sm-4 px-3 py-sm-3 py-2 rounded-4'
						style={{ backgroundColor: COLORS.secondary, color: 'white' }}
					>
						{pair.prompt}
					</p>
					<p
						className='ms-auto mt-1 fs-6 fw-light'
						style={{ color: COLORS.gray_text }}
					>
						{toTime(pair.createdAt)}
					</p>
					<div
						className=''
						style={{
							position: 'absolute',
							bottom: '23px',
							right: 0,
						}}
					>
						<TailRightSvg fill={COLORS.secondary} />
					</div>
				</div>
			</div>
			<div className='d-flex mb-1'>
				<div
					className='rounded-circle d-sm-flex d-none mt-auto mb-1 me-2'
					style={{
						width: '34px',
						height: '34px',
						flexShrink: '0',
						backgroundColor: COLORS.secondary,
					}}
				>
					<StarsSvg className='m-auto' />
				</div>
				<div className='d-flex'>
					<div
						className='d-flex flex-column'
						style={{
							maxWidth: 'min(80vw, 500px)',
							position: 'relative',
							overflowX: 'hidden',
						}}
					>
						<p
							className='px-sm-4 px-3 py-sm-3 py-2 rounded-4'
							style={{ backgroundColor: '#F3F3F3' }}
						>
							{pair.response === '...' ? (
								<div className={classes.DotsLoader}></div>
							) : (
								pair.response
							)}
						</p>
						<p
							className='mt-1 fs-6 fw-light'
							style={{ color: COLORS.gray_text }}
						>
							{toTime(pair.createdAt)}
						</p>
						<div
							className=''
							style={{
								position: 'absolute',
								bottom: '23px',
								left: 0,
							}}
						>
							<TailLeftSvg />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
